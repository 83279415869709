import { useQuery } from "@tanstack/react-query";

import { PublicInformation } from "helpers/queriesDataKeys";
import { API_BASE_URL } from "configs/AppConfig";

import NavList from "./NavList";

import classes from "./Footer.module.css";

const AuctionsNavList = [{ name: "Physical Auction", to: "/physical-bidding" }];
const ConnectNavList = [{ name: "Contact Us", to: "/contact-us" }];

const Footer = () => {
  const {
    isLoading,
    isError,
    data: informations,
    error,
  } = useQuery([PublicInformation], async () => {
    const response = await fetch(`${API_BASE_URL}information/get/all`);

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message);
    }

    return data;
  });
  return (
    <>
      <div className={classes.footer}>
        <div className={classes.footerContent}>
          <NavList
            title="Overview"
            isInformation={true}
            links={informations ? informations.data : []}
          />
          <NavList title="Auctions" links={AuctionsNavList} />
          <NavList title="Connect with Us" links={ConnectNavList} />
          {/* <NavList title="About" links={aboutLinks} />
        <NavList title="About" links={aboutLinks} />
        <NavList title="About" links={aboutLinks} /> */}
        </div>
      </div>
      <div className={classes.footerBottom}>
        <p>
          Copyright vehiclemall.in © {new Date().getFullYear()} v1.3.8. All
          rights reserved | Developed By
        </p>
        &nbsp;
        <span
          style={{ cursor: "pointer" }}
          onClick={() => window.open("https://riolabz.com/", "_blank")}
        >
          Riolabz
        </span>
      </div>
    </>
  );
};

export default Footer;
