import { Link } from 'react-router-dom';
import classes from './NavList.module.css';

const NavList = ({ title, links, isInformation = false }) => {
  return (
    <div className={classes.navListContainer}>
      <h4> {title} </h4>
      <ul className={classes.navList}>
        {links.map((link) => (
          <li key={link.name}>
            <Link style={{color: "black"}} to={isInformation ? `/information/${link._id}` : link.to}>
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NavList;
