import { useCallback, useEffect, lazy, Suspense, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { userActions } from "store-redux/user-slice";

import { APP_PREFIX_PATH } from "configs/AppConfig";

import Layout from "components/layout/Layout";
import DispatchSignInModal from "hooks/DispatchSignInModal";
import LoadingSpinner from "components/UI/loading-spinner/LoadingSpinner";
import Tokens from "pages/physical-bidding/Tokens/Tokens";

const Home = lazy(() => import(`pages/Home`));
const FAQ = lazy(() => import(`pages/FAQ`));
const AboutUs = lazy(() => import(`pages/AboutUs`));
const ContactUs = lazy(() => import(`pages/ContactUs`));
const ForgotPassword = lazy(() =>
  import(`pages/forgot-password/ForgotPassword`)
);
const PaymentSuccess = lazy(() =>
  import(`pages/online-payments/PaymentSuccess`)
);
const PaymentFailed = lazy(() => import(`pages/online-payments/PaymentFailed`));
const Information = lazy(() => import(`pages/Information`));
const Profile = lazy(() => import(`pages/Profile`));
const Dashboard = lazy(() => import(`pages/Dashboard`));
const AuctionIndex = lazy(() => import(`pages/auctions`));
const PhysicalBiddingIndex = lazy(() => import(`pages/physical-bidding`));
const Transactions = lazy(() => import(`pages/transactions/Transactions`));
const Invoices = lazy(() => import(`pages/invoices/Invoices`));
const InvoiceDetail = lazy(() => import(`pages/invoices/InvoiceDetail`));
const Packages = lazy(() => import(`pages/packages/Packages`));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      retryDelay: 2000,
    },
  },
});

const App = () => {
  // access the modalSlice State in the Store Reducer
  const { modalIsVisible, modalType } = useSelector((state) => state.modal);
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const [checkForAuthorization, setCheckForAuthorization] = useState(false);

  const dispatch = useDispatch();

  const autoLoginHandler = useCallback(() => {
    dispatch(userActions.autoLogin());
  }, [dispatch]);

  useEffect(() => {
    // auto login first time app is running
    autoLoginHandler();
    setCheckForAuthorization(true);
  }, [autoLoginHandler]);

  const location = useLocation();

  // differnt types of users according to their subscription status
  const isUserActive =
    isLoggedIn && user?.user?.subscriptionStatus === "Active";
  const isUserExpired =
    isLoggedIn && user?.user?.subscriptionStatus === "Expired";
  const isUserPendingRegistration =
    isLoggedIn && user?.user?.subscriptionStatus === "Pending Registration";
  const isUserDeactivated =
    isLoggedIn && user?.user?.subscriptionStatus === "Deactivated";
  const isUserPendingApproval =
    isLoggedIn && user?.user?.subscriptionStatus === "Pending Approval";
  const grantLoggedInAccess = checkForAuthorization && isLoggedIn;
  const preventAccess = checkForAuthorization && !isLoggedIn;

  // Scroll to top whenever the route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <QueryClientProvider client={queryClient}>
      <Layout
        isDashboard={
          location.pathname.includes("dashboard") ||
          location.pathname.includes("profile")
        }
        notScrollable={modalIsVisible}
      >
        <Routes>
          <Route
            path={`${APP_PREFIX_PATH}`}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Home />
              </Suspense>
            }
          />
          <Route
            path={`${APP_PREFIX_PATH}/information/:informationId`}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Information />
              </Suspense>
            }
          />
          <Route
            path={`${APP_PREFIX_PATH}/information`}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Information />
              </Suspense>
            }
          />
          <Route
            path={`${APP_PREFIX_PATH}/FAQ`}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <FAQ />
              </Suspense>
            }
          />
          <Route
            path={`${APP_PREFIX_PATH}/contact-us`}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <ContactUs />
              </Suspense>
            }
          />
          <Route
            path={`${APP_PREFIX_PATH}/forgot-password/:id`}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <ForgotPassword />
              </Suspense>
            }
          />
          <Route
            path={`${APP_PREFIX_PATH}/payment-success`}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <PaymentSuccess />
              </Suspense>
            }
          />
          <Route
            path={`${APP_PREFIX_PATH}/payment-failed`}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <PaymentFailed />
              </Suspense>
            }
          />
          <Route
            path={`${APP_PREFIX_PATH}/about-us`}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <AboutUs />
              </Suspense>
            }
          />

          {/* Auctions Routes */}
          {grantLoggedInAccess && isUserActive && (
            <Route
              path={`${APP_PREFIX_PATH}/auctions/*`}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <AuctionIndex />
                </Suspense>
              }
            />
          )}

          {grantLoggedInAccess && isUserActive && (
            <Route
              path={`${APP_PREFIX_PATH}/dashboard/transactions`}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <Transactions />
                </Suspense>
              }
            />
          )}

          {grantLoggedInAccess && isUserActive && (
            <Route
              path={`${APP_PREFIX_PATH}/dashboard/invoices`}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <Invoices />
                </Suspense>
              }
            />
          )}

          {/* {grantLoggedInAccess && isUserActive && ( */}
            <Route
              path={`${APP_PREFIX_PATH}/dashboard/invoices/:invoiceId`}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <InvoiceDetail />
                </Suspense>
              }
            />
          {/* )} */}

          {preventAccess && (
            <Route
              path={`${APP_PREFIX_PATH}/auctions/*`}
              element={<DispatchSignInModal />}
            />
          )}

          {/* Physical-bidding Routes */}
          <Route
            path={`${APP_PREFIX_PATH}/physical-bidding/*`}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <PhysicalBiddingIndex />
              </Suspense>
            }
          />

          <Route
            path={`${APP_PREFIX_PATH}/dashboard/tokens`}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Tokens />
              </Suspense>
            }
          />

          {/* <Route
            path={`${APP_PREFIX_PATH}/dashboard/physical-bidding/*`}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <PhysicalBiddingIndex />
              </Suspense>
            }
          /> */}

          {/* Dashboard Routes */}
          {grantLoggedInAccess &&
            isUserActive &&
            user?.user?.participantType === "Buyer" && (
              <Route
                path={`${APP_PREFIX_PATH}/dashboard/*`}
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <Dashboard />
                  </Suspense>
                }
              />
            )}
          {/* this page should be accessible to all type of subscription status */}
          {grantLoggedInAccess && user?.user?.participantType === "Buyer" && (
            <Route
              path={`${APP_PREFIX_PATH}/dashboard/packages/:type`}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <Packages />
                </Suspense>
              }
            />
          )}
          {grantLoggedInAccess && isUserActive && (
            <Route
              path={`${APP_PREFIX_PATH}/profile/*`}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <Profile />
                </Suspense>
              }
            />
          )}

          {/* Redirect Route - according to subscription status its will be redirected to pages*/}
          {checkForAuthorization && (
            <Route
              path="*"
              element={
                <Navigate
                  to={
                    isUserExpired
                      ? `${APP_PREFIX_PATH}/dashboard/packages/renewal`
                      : isUserPendingRegistration
                        ? `${APP_PREFIX_PATH}/dashboard/packages/registration`
                        : isUserDeactivated
                          ? `${APP_PREFIX_PATH}/dashboard/packages/reactivation`
                          : isUserPendingApproval
                            ? `${APP_PREFIX_PATH}/dashboard/packages/verification`
                            : `${APP_PREFIX_PATH}`
                  }
                  replace
                />
              }
            />
          )}
          {/* {checkForAuthorization && (
            <Route
              path="*"
              element={
                <Navigate to={`${APP_PREFIX_PATH}`
                }
                  replace
                />
              }
            />
          )} */}
        </Routes>
      </Layout>
    </QueryClientProvider>
  );
};

export default App;
